module.exports = {
  activity: '/activity',
  'activity.tracking': '/activity/tracking',
  'activity.tracking.current': '/activity/tracking/current',
  'activity.tracking.historic': '/activity/tracking/historic',

  dashboard: '/dashboard',
  dashboard_admin: '/dashboard-admin',

  employee: '/employee',
  'employee.list': '/employee/list',
  'employee.add': '/employee/add',

  payroll: '/payroll',
  'payroll.list': '/payroll/list',
  'payroll.add': '/payroll/add',
  'payroll.settings': '/payroll/settings',

  timecards: '/timecard',
  'timecards.timecard': '/timecard/custom/list',
  'timecards.timecard.list': '/timecard/custom/list',
  // 'timecards.custom.list': '/timecard/custom/list',

  vendor: '/vendor',
  'vendor.list': '/vendor/list',
  'vendor.list.all': '/vendor/list/all',
  'vendor.list.incomplete_signup': '/vendor/list/incomplete-signup',

  geofence: '/geofence',
  'geofence.list': '/geofence/list',
  'geofence.add': '/geofence/add',

  job: '/job',
  'job.list': '/job/list',
  'jobs.new.list': '/job/list/new',
  'job.add': '/job/add',
  'customer.list': '/customers/list',
  'customer.add':'/customers/add',
  'job.reports': '/job/lists/jobreports',
  'task.list' : '/job/lists/task',
  'geofences.list' : '/geofence/job/lists',
  'reports.list' : '/job/lists/jobreports',

  knowledge_base: '/knowledge-base',
  'knowledge_base.list': '/knowledge-base/list',
  'knowledge_base.add': '/knowledge-base/add',
  'knowledge_base.edit': '/knowledge-base/edit',

  report: '/report',

  quality_assurance: '/quality-assurance',
  'quality_assurance.list': '/quality_assurance/list',

  setup_checklist: '/report',

  bill: '/bill',
  'bill.list': '/bill/list',
  'bill.edit': '/bill/edit',

  surveys: '/surveys',
  'surveys.list': '/surveys/list',
  'surveys.dashboard': '/surveys/dashboard',
  'surveys.add': '/surveys/add',
  'surveys.questions': '/surveys/:id/questions',
  'surveys.outcomes': '/surveys/:id/outcomes',
  'surveys.edit': '/surveys/:id/edit',

  forms: '/forms',
 
  'forms.systemforms': '/forms/systemforms',
  'forms.assessmentforms': '/forms/assessmentforms',
  'forms.createfieldform': '/forms/createfieldform',
  'forms.createassessmentform': '/forms/createassessmentform',

  schedulimg:'/schedulimg',
   'scheduling.dashboard':'/scheduling/dashboard',
   'scheduling.shift-management':'scheduling/shift-management',
   
  pto : '/pto',
  'pto.list' : '/pto-policy/time-off/list',
  'pto.policies' : '/pto-policy/list',

  communication:'/communication',
  'communication.list':'/communication/list',
  'document.completion.report':'/document/completion/report',
  'documents.setup':'/document/documentTypes',
  'forms.field': '/forms/fieldforms',

  learning:'/learning',
  'learning.communication.list':'/learning/communication/list',
  'learning.completion.report':'/learning/documents-completion/report',
  'learning.setup':'/learning/setup/categories',
  
  applicant : '/applicant',
  'applicant.list' : '/applicant/list',
  'applicant.add' : '/applicant/add',
  'applicant.edit' : '/applicant/:id/edit/:tab',
};
