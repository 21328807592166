import React from 'react';

import PropTypes from 'prop-types';

import { UncontrolledTooltip } from 'reactstrap';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';
import ViewIconAtom from 'atoms/ViewIcon';

const MealFlagDetailEmployeeTableComponent = (props) => {
  const { data, location: { query }, pageSize, navigation } = props;
  const { page = 1 } = query;
  
  return (
    <div className="ml-3 mr-4">
    <Table className="table-meal-violation-data-detail table-meal-flag-detail" hover responsive size="sm">
      <thead className="Header-col">
        <tr>
          <th className="text-center pl-3">Action</th>
          <th>Employee Name</th>
          <th>Employee Id</th>
          <SortHeaderWithSeparateKeysComponent field='"start_datetime"'>
            Activity Start Date
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='meal_start_datetime'>
            Meal Start Date
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='meal_end_datetime'>
            Meal End Date
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"end_datetime"'>
            Activity End Date
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='violation_type'>
            Violation Type
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"activity_message"'>
            Addressed By
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td className="text-center pl-3">
                <span title="View Activity">
                  <ViewIconAtom onClick={() => navigation(item)} className="cursor-pointer" />
                </span>
              </td>
              <td title={`${item.user_first_name} ${item.user_last_name}`}>
                {item.user_first_name} {item.user_last_name}
              </td>
              <td title={item.employee_id}>{item.employee_id}</td>
              <td title={item.start_datetime}>{item.start_datetime}</td>
              <td title={item.meal_start_datetime}>{item.meal_start_datetime}</td>
              <td title={item.meal_end_datetime}>{item.meal_end_datetime}</td>
              <td title={item.end_datetime}>{item.end_datetime}</td>
              <td title={item.violation_type}>
                {item.violation_type}
              </td>
              {item.activity_message && item.activity_message.length > 0
                ? <td id="abc">
                  {item.activity_message[0].user}
                  <span>
                    <UncontrolledTooltip placement="left" target="abc">
                      {item.activity_message[0].user}
                    </UncontrolledTooltip>
                  </span>
                </td>
                : <td />
              }
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="9">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="9">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

MealFlagDetailEmployeeTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default MealFlagDetailEmployeeTableComponent;