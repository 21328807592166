
import React from "react";
import { server } from "helpers/config";
import Form from "reactstrap/lib/Form";
import { toast } from "react-toastify";
import styles from "../styles.module.scss";
import { ReactComponent as ClearIcon } from "components/common/FilterForm/close.svg";

class ViewTimeOffPolicies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historySlider: false,
      refresh: false,
      policy_id: null,
      policy_name: null,
    };
   
    this.fetchData = this.fetchData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.closeSlider = this.closeSlider.bind(this);
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "OPEN_ACCURAL_HISTORY_SLIDER") {
      this.props.toggleHistorySlider({
        status: true,
        policy_id: data?.content?.policyDetails.id,
        policy_name: data?.content?.policyDetails.name,
      });
    } else if (message === "SUCCESS_TOAST_BALANCE") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "FAILURE_TOAST_BALANCE") {
      toast.error(data?.content?.toastMessage);
    }
  }
  closeSlider() {
    this.setState({ historySlider: false });
  }
  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  fetchData() {
    this.setState({ refresh: true });
    setTimeout(() => this.setState({ refresh: false }));
  }

  render() {
    const { closeSlider, oAuthToken, company_id, employeeId } = this.props;

    return (
      <div className={`${styles["view-time-off-policies-slider"]} w-30`}>
        <Form noValidate>
          <div>
            <div>
              <h4
                className={`${styles["add-activities-heading"]} d-inline-block mt-1 pl-4 mr-2 mb-0`}
              >
                {`View Time Off Policies `}
              </h4>
              <ClearIcon
                className={`${styles["clear-Icon"]} d-inline-block ml-3`}
                height="13"
                width="13"
                onClick={() => closeSlider(false)}
              />
            </div>
            <hr className={`${styles["horizontal-line"]} bg-primary`} />
            <div
              style={{
                height: "calc(100vh - 102px - 46px)",
              }}
            >
              <iframe
                id="scheduling"
                title="scheduling-iframe"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ width: "100%", height: "100%" }}
                src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-policy/employee-edit/timeOff-policies/?oAuthToken=${oAuthToken}&company_id=${company_id}&employee_id=${employeeId}`}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
export default ViewTimeOffPolicies;
