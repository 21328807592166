import React from "react";
import { reduxForm, Field } from "redux-form";

import LoaderAtom from "atoms/Loader";

import Button from "reactstrap/lib/Button";


import ToggleButtonComponent from "pages/qualityAssurance/components/ToggleButtonComponent";

import { ReactComponent as RefreshIcon } from "atoms/RefreshIcon/refresh.svg";

import Form from "reactstrap/lib/Form";
import { STRINGS } from "pages/qualityAssurance/components/Strings";

import FileBrowserAtom from "atoms/FileBrowser";
import { FORM_LAYOUT } from "constants/layout";
import "pages/qualityAssurance/css/TestUpload.css";

const TestUpload = ({
  toggleSwitch,
  isActive,
  tableLoader,
  selected,
  startTest,
  isLoading,
  status,
  isDataChecked,
  handleCheckboxChange,
  handleFileSelect,
  handleSubmit,
}) => {
  return (
    <div className="form-container">
      <Form onSubmit={handleSubmit} noValidate className="ml-3 mr-4">
        <div className="form-content">
          <div className="file-upload-section">
            <Field
              accept=".csv,.apk"
              id="test"
              name="test"
              label={
                selected === "Web"
                  ? STRINGS.BROWSE_LABEL_WEB
                  : STRINGS.BROWSE_LABEL
              }
              component={FileBrowserAtom}
              type="file"
              placeholder="Choose File"
              layout={FORM_LAYOUT}
              onChange={handleFileSelect}
            />

            <Button
              style={{ height: "2.2rem" }}
              type="submit"
              color="secondary"
            >
              Upload
            </Button>
          </div>

          <div
            className={
              status === "IN_PROGRESS" || status === "SUCCESS"
                ? "status-box-success"
                : "status-box-danger"
            }
          >
            <div className="refresh-card-style">
              <RefreshIcon
                height="1rem"
                width="1rem"
                onClick={() => startTest("Refresh")}
              />
            </div>
            <div style={{ textAlign: "center", marginBottom: "-1.5rem" }}>
              <p className="status-text">Status</p>
              <p>{isLoading ? <LoaderAtom active /> : status}</p>
            </div>
          </div>
        </div>

        <div className="checkbox-toggle-section">
          <label>
            <input
              className="checkbox-input"
              type="checkbox"
              checked={isDataChecked}
              onChange={handleCheckboxChange}
            />
            Locator File
          </label>

          <ToggleButtonComponent
            toggleSwitch={toggleSwitch}
            isActive={isActive}
            tableLoader={tableLoader}
          />
        </div>
      </Form>
    </div>
  );
};

TestUpload.propTypes = {};

export default reduxForm({
  form: "testUploadForm",
})(TestUpload);
