import React from "react";

import Form from "reactstrap/lib/Form";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";

import { toast } from "react-toastify";
import closeimage from "./Close-Cross.png";

class AddTimeOffTimecardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "SUCCESS_PTO_TOAST") {
      toast.success(data?.content?.toastMessage);
      this.props.toggle();
      this.props.updateState('ptoWarningModal' , true);
      this.props.updateState('ptoWarningMessage' , data?.content?.warning);
      this.props.fetchData();
    } else if (message === "FAILURE_PTO_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "CLOSE_ADD_TIME_OFF_SLIDER") {
      this.props.toggle();
    }
  }
  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }
  render() {
    const {
      toggle,
      isOpen,
      employeeName,
      oAuthToken,
      company_id,
      employeeId,
      selectedDate,
      ptoMode,
      ptoPolicyId,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        height="650px"
        autoComplete="off"
      >
        <ModalHeader>
          {`${ptoMode} Paid Time Off`}
          <img
            src={closeimage}
            className="close_image"
            height="14px"
            width="14px"
            alt="Close"
            onClick={() => toggle()}
          />
        </ModalHeader>
        <Form noValidate>
          <ModalBody>
            <div>
              <div
                style={{
                  height: "calc(100vh - 102px - 46px)",
                }}
              >
                {
                  <iframe
                    id="scheduling"
                    title="scheduling-iframe"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/timecard/pto-request?oAuthToken=${oAuthToken}&company_id=${company_id}&employee_id=${employeeId}&employee_name=${employeeName}&selected_date=${selectedDate}&policyId=${ptoPolicyId}&tabId=${ptoMode}`}
                  />
                }
              </div>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    );
  }
}

export default AddTimeOffTimecardModal;
